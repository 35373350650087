.home section.banner img,
img.abs_img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
}

.panel {
  border-radius: 0;

  &.panel-default {
    box-shadow: none;
    border-color: transparent;
  }

  h2 {
    margin-top: 0;
  }
}

.content-container {
  max-width: 600px;
  margin: 0 auto;
}

section {
  padding: 50px 0;

  &.home-header {
    background-size: cover !important;
    position: relative;
    z-index: 2;

    @media(max-width: 767px) {
      background-position: 57% 40% !important;
    }

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: -1;
      left: 0;
      top: 0;
      opacity: 0.4;
      background: black;
    }

    img {
      margin: 0 auto;
      height: 125px;

      @media(max-width: 767px) {
        height: 90px;
      }
    }
  }
}


.home-header-content {
  p {
    padding-bottom: 2rem;
    color: #fff;
  }
}

#home-header-paragraph {
  color: #fff;
  padding-bottom: 0;
  font-size: 6rem;

  @media(max-width: 767px) {
    font-size: 4rem;
  }
}

.services-home p {
  padding: 0 25px;
  text-align: center;
}

.service-title {
  display: table;
  vertical-align: bottom;
  margin-bottom: 20px;
  width: 100%;
  min-height: 60px;

  h3 {
    text-align: center;
    display: table-cell;
    vertical-align: bottom;
  }
}

@media (min-width: 992px) {
  .row.flex-row {
    display: flex;
    clear: both;
  }
}

.home-address .b-r:last-child,
.services-home .b-r:last-child {
  border-right: 0;
}

.new-beginning {

  p {
    font-size: 4.8rem;
    font-family: $font-header;
    background-color: $brand-tertiary;
    color: #000;
    padding: 15px 10px 22px;
    margin: 15px 0;
    line-height: 1.2;

    @media(max-width: 767px) {
      font-size: 3.5rem;
    }
  }
}

.inner {
  text-align: center;
  padding: 1.5rem 0;
}


.faq-img,
.option-img {
  position: relative;

  img {
    width: 100%;
    max-height: 500px;
    object-fit: cover;

    @media (max-width:480px) {
      max-height: 400px;
      object-fit: cover;
    }
  }
}

.caption-middle {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  transition: all .85s;
  background-color: rgba($brand-primary, .8);

  &:hover {
    border-color: rgba(0, 0, 0, 0);
    opacity: 1;
    background-color: rgba($brand-secondary, .7);

    .caption {
      transform: translateY(50%);
      bottom: 50%;
    }
  }
}

.caption {
  position: absolute;
  bottom: 15px;
  transition: all 1s;
}

.faq-title h3 {
  margin: 30px 0;
  text-align: left;
  padding: 0 15px;
  color: #fff;
  font-size: 4.5rem;

  @media (min-width: 768px) {
    font-size: 3.5rem;
  }

  @media (min-width: 992px) {
    font-size: 4.5rem;
  }

}


.faq-content {
  margin-bottom: 30px;
}

.home-address {
  padding: 25px 0;
  text-align: center;
}

.style-guide-page,
section.option-box {}

.option-middle {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  text-align: center;
  border: 0px solid;
  transition: all .85s;
  background-color: rgba($brand-primary, .6);
}

.option-content {
  width: 100%;
  height: 100%;
  display: table;
  position: relative;
}

.option-title {
  display: table-cell;
  vertical-align: bottom;
  width: 100%;
  height: 100%;
  position: relative;
  bottom: 40px;

  h5 {
    color: #fff;
    margin-bottom: 20px;
    font-size: 50px;

    @media(min-width: 768px) {
      font-size: 40px;
    }

    @media(min-width: 992px) {
      font-size: 50px;
    }
  }
}

.sec-content hr {
  float: left;
  margin-left: 0px;
}

.sec-content .content-container {
  margin: 0;
}

.event-page {
  margin-bottom: 100px;
  float: left;

}



.event-image {
  padding: 100px;
}

.date {
  background-color: $brand-primary;
  color: #fff;
  float: left;
  padding: 10px 0;
  font-size: 60px;
  position: absolute;
  left: -10px;
  width: 100px;
  top: -15px;
  font-family: $font-header;
  line-height: 50px;

  span {
    font-size: 40px;
  }
}

.event-page h2,
.single-event-page h2 {
  margin-top: 0;
  font-family: $font-header;
}



.single-event-page .event-loc {
  margin-top: 25px;
}

.single-event-page .date {
  left: 0;
  top: 0;
}

.widget.text-6.widget_text,
.widget.text-9.widget_text {
  margin: 0 auto;
  display: block;
  width: 275px;
  margin-top: 25px;
}

.textwidget hr {
  width: 230px;
  margin: 15px 0;
}

.style-guide-page {
  padding: 50px 0;
}

.buttons {
  background-color: #ebebeb;
  padding: 10px 0;
}

.sidebar {
  margin-top: 35px;
}

.sidebar-primary .main {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  z-index: 1;
}

@media (min-width: 768px) {
  .sidebar {
    padding: 50px 15px;
  }

  .nobr br {
    display: none;
  }
}

@media (max-width: 1199px) {

  .option-title {
    bottom: 20px;
  }

  .home section.banner {
    height: 450px;
  }

  .sidebar .panel-body .map {
    position: relative;
    padding-bottom: 75%; // This is the aspect ratio
    height: 0;
    overflow: hidden;
  }

  .sidebar .panel-body .map iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

@media (max-width: 991px) {

  iframe {
    margin: 0 auto;
    display: block;
  }

  .home section.banner {
    height: 400px;
  }


  .secondary.banner h1 {
    @media(max-width: 767px) {
      margin: 110px 0 10px;
    }
  }

  .service hr {
    margin-bottom: 25px;
  }

  .service-title {
    min-height: 0px;
  }

}

@media (max-width: 767px) {
  .stellarnav.mobile.dark ul {
    max-height: calc(100vh - 155px);
    overflow: auto;
  }

  .faq-img,
  .option-img {
    margin: 15px 0;
  }

  iframe {
    width: 100%;
  }

  .style-guide-page,
  section.option-box {
    padding: 30px 0;
  }

  .main-header,
  .main-header .navbar {
    height: 145px;
  }

  .mobile-phone .phone,
  .mobile-phone .text {
    padding: 0px;
  }

  .mobile-phone a {
    width: 100%;
    display: block;
    padding: 7px 0;
    color: #000;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 1px;

  }

  .mobile-phone .phone a {
    background-color: $brand-primary;
    color: #fff;
  }

  .mobile-phone .text a {
    background-color: $brand-secondary;


  }

  .menu-toggle .fa {
    border: 1px solid $brand-primary;
    background-color: $brand-primary;
  }

  .stellarnav.mobile li a {
    border-bottom: 1px solid rgba(255, 255, 255, .15) !important;
    background-color: $brand-primary !important;
  }

  .stellarnav.active a {
    border-bottom: 0 !important;
  }

  .stellarnav li a {
    padding: 5px 15px;
  }

  .navbar-default .navbar-nav>li>a {
    font-size: 18px;
    padding: 15px 15px;
  }

  a.dd-toggle {
    padding: 5px;
    height: auto;
    min-height: 40px;
  }

  .home section.banner {
    height: 400px;
  }

  .stellarnav {
    display: block;
    clear: both;
    overflow: inherit;
  }

  .navbar-nav {
    margin: 7.5px -15px 0;
  }

  a.menu-toggle.full {
    margin-top: 0px;
    top: 22px;
    right: 0px;
  }

  .sidebar {
    max-width: 100%;
    margin: 15px auto 35px;
  }

  .navbar-brand {
    padding: 11px 15px;
  }


  .navbar .navbar-brand {
    max-width: 212px;
    position: relative;
    z-index: 9999;
  }

  .sidebar-primary .main {
    float: none;

  }

  .home-map {
    position: relative;
    padding-bottom: 75%; // This is the aspect ratio
    height: 0;
    overflow: hidden;
  }

  .home-map iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }

  .home-address .b-r {
    border-bottom: 4px solid $brand-secondary;
    max-width: 250px;
    margin: 0 auto 25px;
  }

  .home-address .b-r:last-child {
    border-bottom: 0;
  }
}

@media (max-width: 548px) {
  .nobr br {
    display: block;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUpBig {
  animation-name: fadeInUpBig;
}

.highlight_bg {
  border: 1px solid #7e0656;
  display: inline-block;
  padding: 10px;
  border-radius: 3px;
  color: #fff;
  background: #7e0656;
}

.highlight_bg {
  a {
    color: inherit;

    &:hover {
      color: #95c63f;
    }
  }

}