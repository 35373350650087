@mixin button($color-primary: $brand-primary, $color-secondary: $brand-secondary) {
    background-color: #95c63f;
   border-radius: 0;
   border-width: 3px;
   text-transform: uppercase;
   color: #0a0000;
   border: 1px solid #95c63f;
   padding: 16px 34px;
   font-size: 21px;
   display: inline-block;
   transition: all 100ms ease;
    box-shadow: none;
   &:hover, &:focus {
	 background-color: #b4c68f;
      border-color: #b4c68f;
      color: #0a0000;
      border: 1px solid #b4c68f;
   }
}

.btn-default, body .gform_wrapper .gform_footer input.button,
body .gform_wrapper .gform_footer input[type=submit], .btn, body .wp-block-button__link, body .wp-block-button__link:visited {
 @include button();
}


.btn-default2 {
  @include button();
}

.btn-white {
  @include button(#ffffff, #ffffff);
}
.error404 .search-form .search-submit {
  
    padding: 5px 35px;
    font-size: 16px;
   
}
.btn-xl{
	padding: 20px 40px;
    font-size: 22px;
}