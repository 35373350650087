.footer-contact-form {
  background: $brand-accent;
  padding: 6rem 0;
  h2 {
  color: $brand-primary;
  }
  hr {
    border-top: 3px solid $brand-secondary;
}
 
}

footer.content-info {
  border-top: 40px solid $brand-secondary;
  background: $brand-primary;
  color: #fff;
  padding: 3rem 0;

  .footer-copyright {
    font-size: 12px;
    padding-top: 0;
  }
}

.footer-logo img {
	margin: 20px auto 0;
    max-width: 190px;
    width: 100%;
}

.footer-menu {
  padding-top: 20px;
}


ul {
  &.social {
    text-align: center;
	@media (min-width:768px) {
    padding-top: 30px;
	}
    padding-left: 0;
    li {
      display: inline-block;
    }
  }
}


.footer-menu ul {
  padding: 0;
  text-align: center;
  margin-bottom: 15px;
  @media(max-width:767px) {
    display: grid;
  }

  li {
    display: inline-block;
    padding: 1rem;

    a {
      text-transform: uppercase;
      color: #fff;
      font-weight: 400;
      font-family: $font-body;
      font-size: 18px;
      padding: 10px;
    }
 ul
 {
 	display: none;}
  }
}

.footer-menu ul li a:focus,
.footer-menu ul li a:hover {
  color: #fff;
  text-decoration: none;
}



ul.social a {
  font-size: 50px;
  color: #3F3F3F;
  text-decoration: none;
      margin-right: 8px;
    margin-left: 8px;

  &:hover {
    color: $brand-primary;
  }
}

.footer-address {
  font-size: 14px;

  @media (max-width:360px) {
    font-size: 13px;
  }
}

.add-1 {
  padding-top: .4rem;
  padding-bottom: 1.4rem;

  a {
     color: #fff;
 }

}
.timing-1,
.timing-2 {
  padding: 15px 0;
}

.foo-bottom {
    padding: 2.5rem 0 1.8rem;
    font-size: 14px;
    text-align: center;
	a {color: #ffc573;}
	a:hover
	{
	color: #fff;
	}
}


.add-1 span {
    display: inline-block;
    padding: 0px 15px;
    border-right: 2px solid;
	&:last-child
	{
	border-right: none;
	}
}
@media(max-width: 991px) {
.footer-menu ul li a{
    font-size: 16px;
    padding: 10px 0px;
}
}
@media(max-width: 767px) {
.add-1 span {
    display: block;
    border-right: none;
	padding: 5px 15px 7px;
	&.phone_number{
	padding-top: 10px;
	}

}

.footer-logo img {
    margin: 30px auto 10px;
}
}