

@font-face {
    font-family: 'FontAwesome';
    font-display: swap;
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/fontawesome-webfont.woff2') format('woff2'),
        url('../fonts/fontawesome-webfont.woff') format('woff'),
        url('../fonts/fontawesome-webfont.ttf') format('truetype'),
        url('../fonts/fontawesome-webfont.svg#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
$font-script: 'Shadows Into Light Two', cursive;
$font-header:'Shadows Into Light Two', cursive;
$font-body: 'Roboto', sans-serif;
$font-awesome:		'FontAwesome';

// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #7F886A;
$brand-secondary:		#F1AC96;
$brand-tertiary:		#95C63F;
$brand-cta:				#00283f;
$brand-accent: 			#F3EBDB;
