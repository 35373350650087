input:focus, optgroup:focus, select:focus, textarea:focus{
    outline: $brand-secondary auto 5px!important;
}
button:focus{
 outline: 0 !important;
}
// Search form
.search-form {
  @extend .form-inline;
}

.search-form label {
  font-weight: normal;
  @extend .form-group;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}
::-webkit-input-placeholder {
	color: #000;
}

::-moz-placeholder {
	color: #000;
}

:-ms-input-placeholder {
	color: #000;
}

:-moz-placeholder {
	color: #000;
}

body .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
	padding: 5px 10px;
}
body .gform_wrapper .gform_footer {
    text-align: center;
}
body .gform_wrapper .gform_footer {
    padding: 0px 0 10px;
    margin: 15px 0 0;
    clear: both;
    width: 100%;
    text-align: center;
}
body .gform_wrapper .gform_footer input.button,
body .gform_wrapper .gform_footer input[type=submit] {
	width: auto;
	background-color: transparent;
	border-radius: 0;
	border-width: 3px;
	text-transform: uppercase;
	color: $brand-primary;
	border: 1px solid $brand-primary;
	padding: 10px 40px;
	font-size: 21px;

}
.panel-body .gform_wrapper{
    margin-bottom: 0;
}
body .gform_wrapper {
	.top_label {

		input.medium,   select.medium, textarea {
			border: 0;
			background: #fff;
			width: 100%;
                        color:#000;
		}

		.gfield_label {
			display: none;
		}
             
	}

}
body{
    &.contact-us, .sidebar
    {
        .gform_wrapper {
	.top_label {

		input.medium,   select.medium, textarea {
			border: 0;
			background: #efefef;
			width: 100%;
                        color:#000;
		}

		.gfield_label {
			display: none;
		}
                &#gform_fields_1
                {
                    
                }
	}

}
    }
}
body 
{
.gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit], .gform_wrapper .gform_page_footer input.button, .gform_wrapper .gform_page_footer input[type=submit] {
    margin: 0 0px 0 0;
}
.gform_wrapper ul.gform_fields li.gfield {
    padding-right: 0;
}
&.contact-us {
.gform_wrapper .gform_footer {
    text-align: right;
}
}
}
.gform_wrapper ul li:after, .gform_wrapper ul li:before, .gform_wrapper ul.gform_fields {
    padding: 0;
    margin: 0;
    overflow: visible;
}

.gform_wrapper ul.gform_fields {
    margin: 0!important;
    list-style-type: none;
    display: block;
}
body .gform_wrapper ul li.gfield {
    margin-top: 16px;
    padding-top: 0;
}
.gform_wrapper ul, html>body .entry ul {
    text-indent: 0;
}
.gform_wrapper textarea {
    font-size: inherit;
    font-family: inherit;
    letter-spacing: normal;
    padding: 6px 8px;
    line-height: 1.5;
    resize: none;
}
.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
    font-size: inherit;
    font-family: inherit;
    letter-spacing: normal;
}
.gform_wrapper textarea.medium {
    height: 120px;
    width: 100%;
}
.gform_wrapper li.gfield.gfield_error, .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
    background-color: rgba(255,223,224,.25);
    margin-bottom: 6px!important;
    border-top: 1px solid #c89797;
    border-bottom: 1px solid #c89797;
    padding-bottom: 6px;
    padding-top: 8px;
    box-sizing: border-box;
}
.gform_wrapper .validation_message {
    color: #790000;
    font-weight: 700;
    letter-spacing: normal;
}
.gform_wrapper div.validation_error {
    color: #790000;
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 25px;
    border-top: 2px solid #790000;
    border-bottom: 2px solid #790000;
    padding: 16px 0 16px 0;
    clear: both;
    width: 100%;
    text-align: center;
}