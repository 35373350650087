.top-bar {
    background: $brand-primary;
    padding: 1rem 1rem;
    border-bottom: 1px solid #fff;	
}

.top-bar p {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin: 0;
}
.top-bar p a
{
    text-decoration:underline;
    color: #fff;
}
section.banner {
    border: 0;
    margin: 0;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    height: 40vh;
    .home & {
        height: calc(100vh - 125px);
    }
}

.home section.banner h1 {
    text-align: center;
    color: #fff;
    font-weight: normal;
    font-size: 8.5rem;
    font-family: $font-header;
    @media(max-width: 991px) {
        font-size: 6.5rem;
    }
    @media(max-width: 767px) {
        font-size: 4.5rem;
    }
}

.secondary.banner {
    position: relative;
    z-index: 9;
    h1 {
        font-family: $font-header;
        background-color: rgba(255,255,255,.85);
        text-align: left;
        display: inline-block;
        color: $brand-primary;
        padding: 15px 10px;
        margin: 240px 0 10px;
    }
}


ul#menu-header-navigation {
    padding-top: 20px;
    margin-right: 0;
}

.main-header, .main-header .navbar {
    height: 125px;
}
.navbar-brand > img {
    position: relative;
    z-index: 999;
}

.navbar {
    margin-bottom: 0;
    border: 0;
    position: fixed;
    z-index: 99;
    width: 100%;
    transition: all 250ms ease;
    border-radius: 0;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);

    .navbar-brand {
        height: auto;
    }

    .dropdown-menu .active {
        a {
            color: $brand-tertiary;
            border-bottom: 2px solid;
            background-color: transparent;
        }
    }
}

.navbar-default .navbar-nav > li > a {
    text-transform: uppercase;
    color: $brand-primary;
    font-weight: 400;
    font-family: $font-body;
    font-size: 18px;
    padding: 15px 20px 5px;
    @media (max-width: 1199px) {
        padding: 15px 14px 5px;
    }
}
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
    color: $brand-secondary;
    background-color: transparent;
    border-bottom: 2px solid;
}
.dropdown-menu {
    left: 0;
    padding: 5px;
    border: 2px solid $brand-tertiary;
}

.dropdown-menu > li > a {
    padding: 3px 5px;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
    background-color: $brand-tertiary;
    color: #fff;
}

.head-address {
    text-align: right;
    padding: 0 30px;
    clear: both;
    position: relative;
    top: -55px;
    .btn{
        padding: 6px 18px;
    font-size: 16px;
    margin-right: 10px;
    }
}

.head-address span, .head-address span a {
    color: #929397;
    padding-right: 10px;
}

ul.head-social {
    position: relative;
    padding: 0;
    margin: 0;
    width: 50px;
    float: none;
    display: inline-block;

    li {
        list-style: none;
        display: inline-block;
        padding: 0 3px;
        a {
            font-size: 18px;
            color: $brand-secondary;
        }
    }
}


.navbar-brand > img {
    max-width: 95px;
}

header{
    @media (max-width: 1199px) {
        .container
        {
            width: 100%;
            position: relative;
        }
    }
}

.dropdown .dropdown>a:after
{
    content: "\f067";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 5px;
    top: 6px;
}
.navbar-right .dropdown-menu .dropdown-menu {
    right: 0;
    left: 100%;
    top: 0px;
}
@media (max-width: 767px) {
    .stellarnav.mobile li.has-sub a {
        padding-right: 50px;
        padding: 15px;
    }
    .stellarnav.mobile .sub-menu li a {
        padding-left: 30px !important;
    }
}

@media (max-width: 991px) {
    .stellarnav.mobile li.has-sub a {
        padding-right: 50px;
        padding: 15px;
    }
    .stellarnav.mobile .sub-menu li a {
        padding-left: 30px !important;
    }

    .stellarnav.mobile.dark ul {
        max-height: calc(100vh - 155px);
        overflow: auto;
    }

    .mobile-phone  a{
        width: 100%;
        display: block;
        padding: 7px 0;
        color: #000;
        text-transform: uppercase;
        font-size: 16px;
        margin-bottom: 1px;

    }
    .mobile-phone .phone a {    
        background-color: $brand-primary;
        color: #fff;
    }
    .mobile-phone .text a{    
        background-color: $brand-secondary;
    } 
    .mobile-phone .donate a{    
        background-color:#91c441;
    }
    .menu-toggle .fa {
        border: 1px solid $brand-primary;
        background-color: $brand-primary;
    }
    .stellarnav.mobile li a {
        border-bottom: 1px solid rgba(255,255,255,.15) !important;
        background-color: $brand-primary !important;
    }
    .stellarnav.active a {
        border-bottom: 0 !important;
    }
    .stellarnav li a {
        padding: 5px 15px;
    }
    .navbar-default .navbar-nav>li>a {
        font-size: 18px;
        padding: 15px 15px;
    }
    a.dd-toggle {
        padding: 5px;
        height: auto;
        min-height: 40px;
    }
    .stellarnav {
        display: block;
        clear: both;
        overflow: inherit;
    }

    .navbar-nav {
        margin: 7.5px -15px 0;
    }

    a.menu-toggle.full {
        margin-top: 0px;
        top:22px;
        right:0px;
    }


    .navbar-brand
    {
        padding: 11px 15px;
    }
    .navbar .navbar-brand {
        max-width: 212px;
        position: relative;
        z-index: 9999;
            margin-left: 0px !important;
    }
    .navbar-nav, .navbar-nav>li{
        float: inherit;
    }
    .main-header, .main-header .navbar {
    height: 145px;
}
.mobile-phone .phone, .mobile-phone .text, .mobile-phone .donate {
    padding: 0;
}
}