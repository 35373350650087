.sidebar-1 {
  .panel-heading {
    padding: 0;
  }

  .panel {
    border: 0;
    box-shadow: none;
  }

  .nav-tabs > li > a {
    font-family: $font-header;
    font-size: 24px;
    color: $brand-primary;
  }

  .panel-body {
    padding: 15px;
    border-left: 2px solid;
    border-right: 2px solid;
    border-bottom: 2px solid;
    border-color: $brand-secondary;
  }

}

.sidebar-1 .nav-tabs > li.active > a,
.sidebar-1 .nav-tabs > li.active > a:focus,
.sidebar-1 .nav-tabs > li.active > a:hover {
  color: $brand-primary;
  background-color: #fff;
  border: 2px solid $brand-secondary;
  border-bottom-color: transparent;
}

.sidebar-1 .nav > li > a:focus,
.sidebar-1 .nav > li > a:hover {
  background-color: transparent;
}

.sidebar-1 .nav-tabs > li > a:hover {
  border-color: transparent;
}

.sidebar-1 .nav-tabs {
  border-bottom: 2px solid $brand-secondary;

  > li {
    outline: 0;
    margin-bottom: -2px;
  }
}

.testi-block {
  padding: 10px;
  border: 2px solid $brand-tertiary;
}

.sidebar-3 {
  margin-top: 20px;
}
.testi-author
{
    font-weight: bold;
    text-align: right;
    padding-right: 15px;
    margin-top: 15px;
}