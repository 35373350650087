body {
  font-weight: 400;
  font: 16px/22px $font-body;
  color: #000;
  -webkit-font-smoothing: antialiased;
}
.sidebar{
blockquote {
  border-left: 0;
  line-height: 22px;
  min-height: 0;
  display: block;
  position: relative;
  padding: 0 10px;
  font-size: 16px;
  margin: 0;
}

blockquote:after,
blockquote:after,
blockquote:before {
  font-size: 22px;
  font-weight: 700;
  position: absolute;
}

blockquote:before {
  content: "\201C";
  left: 0;
  top: 0;
}

blockquote:after,
blockquote:after {
  content: "\201D";
  bottom: 0;
  height: 20px;
}
}
.textwidget {
  margin-bottom: 20px;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
}
a{
    color:#95c63f;
    &:focus,
&:hover 
{
        color:#555e46;
    }
}
.lazy {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px/1 $font-awesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.fa-facebook-f:before,
.fa-facebook:before {
    content: "\f09a";
}
.fa-facebook-square:before {
    content: "\f082";
}
.fa-instagram:before {
    content: "\f16d";
}
.fa-twitter:before {
    content: "\f099";
}
.fa-twitter-square:before {
    content: "\f081";
}
.fa-youtube:before {
    content: "\f167";
}
.fa-plus:before {
    content: "\f067";
}
.fa-minus:before {
    content: "\f068";
}
.fa-bars:before,
.fa-navicon:before,
.fa-reorder:before {
    content: "\f0c9";
}

hr {
  margin-top: 10px;
  margin-bottom: 50px;
  border: 0;
  border-top: 3px solid $brand-secondary;
  width: 60px;
}
article.hentry {
    padding-bottom: 2rem;
    border-bottom: 4px solid $brand-secondary;
}
.b-r {
  @media (min-width: 991px) {
    border-right: 4px solid $brand-secondary;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-header;
  color: $brand-primary;
  text-transform: capitalize;
}
h3,
h4,
h5,
h6{
  color: $brand-cta;
}
@media(min-width: 768px) {
h1 {
  font-size: 5.5rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 3rem;
}

h4 {
  font-size: 2.5rem;
}

h5 {
  font-size: 2rem;
}

h6 {
  font-size: 1.5rem;
}
}
p {
   margin-bottom: 1.5rem;
}

.margin-top {
  margin-top: 50px;
}

.img-align p {
  margin-bottom: 20px;
}

.img-align hr {
  margin-bottom: 30px;
  margin-left: 0;
}

.img-align h2 {
  margin-top: 0;
}

.wp-caption-text {
  text-align: center;
  font-family: $font-script;
  font-size: 35px;
}

#map {
  width: 100%;
  height: 300px;
  overflow: visible;
  top: 150px;
}

.controls {
  margin-top: 0;
  height: 35px;
  outline: none;
}

#searchInput {
  background-color: #fff;
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 50%;
}

#searchInput:focus {
  border-color: #4d90fe;
}

.tab-content > .tab-pane {
  overflow: hidden;
}

input#pac-input {
  top: -150px;
  width: 100%;
  left: 0;
  border: 1px solid;
  font-size: 18px;
}

input#searchsubmit {
  position: absolute;
  right: 33px;
  z-index: 9;
  height: 35px;
  border: 0;
  border-left: 2px solid;
  padding: 0 15px;
  font-size: 16px;
}
